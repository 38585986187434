<template>
  <div
    class="py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center"
  >
    <div class="flex items-center">
      <div class="ml-4">
        <p class="text-grey-darkest">{{ contact.number }}</p>
      </div>
      <div v-if="activeDID" class="ml-4">
        <router-link
          title="Call"
          :to="`/live?phone_number=${contact.number}&did_number=${activeDID}`"
          class="text-indigo-600 hover:text-indigo-900"
          ><PhoneIcon class="h-4 w-4" aria-hidden="true"
        /></router-link>
      </div>
    </div>

    <div class="flex items-center">
      <div class="flex mr-4">
        <span class="mr-2">Screenshot Mode</span>
        <Switch
          v-model="localScreenshotMode"
          :class="[
            localScreenshotMode ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
          ]"
        >
          <span
            aria-hidden="true"
            :class="[
              localScreenshotMode ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            ]"
          />
        </Switch>
      </div>
      <Menu as="span" class="relative block">
        <MenuButton
          class="
            inline-flex
            items-center
            rounded-r-md
            text-sm
            font-medium
            hover:bg-gray-100
            focus:outline-none
            border-2
          "
        >
          <span class="py-2 pl-2 pr-24" v-if="activeDID">{{
            didList[activeDID].text
          }}</span>
          <span class="py-2 pl-2 pr-32" v-if="activeDID === null"
            >Select Active DID</span
          >
          <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="
              z-50
              origin-top-right
              absolute
              right-0
              mt-2
              -mr-1
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              focus:outline-none
              overflow-y-auto
              max-h-96
            "
          >
            <div class="py-1">
              <MenuItem
                v-for="DID in didList"
                :key="DID.did_number"
                v-slot="{ active }"
                @click="this.$emit('updateActiveDID', DID.did_number)"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    DID.seen ? '' : 'text-red-700',
                    DID.is_deleted ? 'line-through' : '',
                    DID.outreach === outreachMode ? '' : 'hidden',
                    'block px-4 py-2 text-sm',
                  ]"
                >
                  {{ DID.text }} ({{ DID.messages_count }} Message)
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, PhoneIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems, Switch } from "@headlessui/vue";
import _ from "lodash";

export default {
  components: {
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    ChevronDownIcon,
    PhoneIcon,
    Switch,
  },
  props: {
    contact: {
      type: Object,
      default: null,
    },
    activeDID: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapActions("smsStore", ["setScreenshotMode"]),
  },
  computed: {
    didList() {
      const DIDsMapped = this.DIDs.map((DID) => {
        const DIDMessages = this.contact.messages_dids
          ? this.contact.messages_dids[DID.did_number] ?? {
              messages: [],
              seen: true,
            }
          : {
              messages: [],
              seen: true,
            };
        return {
          did_number: DID.did_number,
          mailbox_name: DID.mailbox_name,
          seen: DIDMessages.seen ?? true,
          messages_count: DIDMessages.messages.length,
          text: `${DID.did_number} (${DID.mailbox_name})`,
          outreach: DID.outreach,
          is_deleted: DID.is_deleted,
        };
      });
      return _.keyBy(
        DIDsMapped.sort(
          (firstItem, secondItem) =>
            secondItem.messages_count - firstItem.messages_count
        ),
        "did_number"
      );
    },
    ...mapGetters("calls", ["DIDs"]),
    ...mapGetters("smsStore", ["screenshotMode", "outreachMode"]),
    localScreenshotMode: {
      get() {
        return this.screenshotMode;
      },
      set(value) {
        this.setScreenshotMode(value);
      },
    },
  },
};
</script>
