<template>
  <div>
    <div class="mx-auto">
      <div style="height: calc(100vh - 100px)">
        <div class="flex border border-grey rounded shadow-lg h-full">
          <!-- Left -->
          <div class="w-1/3 border flex flex-col">
            <!-- Header -->
            <SMSLeftHeader />

            <!-- Search -->
            <div class="py-2 px-2 bg-grey-lightest flex justify-between">
              <input
                v-model="searchText"
                @change="searchContacts"
                type="text"
                class="w-1/2 px-2 py-2 text-sm rounded-full"
                placeholder="Search or start new chat"
              />
              <Menu as="span" class="relative block">
                <MenuButton
                  class="
                    inline-flex
                    items-center
                    rounded-r-md
                    text-sm
                    font-medium
                    hover:bg-gray-100
                    focus:outline-none
                    border-2
                  "
                >
                  <span class="py-2 pl-2 pr-32">Search By DID</span>
                  <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      z-50
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      -mr-1
                      rounded-md
                      shadow-lg
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                      overflow-y-auto
                      max-h-96
                    "
                  >
                    <div class="py-1">
                      <MenuItem
                        v-for="DID in DIDs"
                        :key="DID.did_number"
                        @click="
                          searchText = DID.did_number;
                          searchContacts();
                        "
                      >
                        <a
                          href="#"
                          :class="[
                            'text-gray-700 block px-4 py-2 text-sm',
                            DID.outreach === outreachMode ? '' : 'hidden',
                            DID.is_deleted ? 'line-through' : '',
                          ]"
                        >
                          {{ DID.did_number }} ({{ DID.mailbox_name }})
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>

            <!-- Contacts -->
            <div class="bg-grey-lighter flex-1 overflow-auto">
              <p
                class="align-middle text-center"
                v-if="outreachMode && contacts.length === 0"
              >
                There are no outreach numbers defined
              </p>
              <SMSContact
                v-if="searchText"
                :contact="searchContact"
                :activeNumber="activeContact?.number"
                @click="setActiveContact(searchContact, true)"
              />
              <SMSContact
                v-for="contact in contacts"
                :key="contact.number"
                :contact="contact"
                :activeNumber="activeContact?.number"
                @click="setActiveContact(contact)"
              />
            </div>
          </div>

          <!-- Right -->
          <div class="w-2/3 border flex flex-col" v-if="activeContact">
            <!-- Header -->
            <SMSRightHeader
              :activeDID="activeDID"
              @updateActiveDID="updateActiveDID"
              :contact="activeContact"
            />

            <!-- Messages -->
            <div class="flex-1 overflow-auto" style="background-color: white">
              <div class="py-2 px-3" id="smsMessages" v-if="activeDID">
                <div
                  class="flex justify-center mb-4"
                  v-if="activeDID && activeMessages.length === 0"
                >
                  <div
                    class="rounded py-2 px-4"
                    style="background-color: #fcf4cb"
                  >
                    <p class="text-xs">
                      This is the beginning of conversation for this number with
                      the selected DID
                    </p>
                  </div>
                </div>

                <SMSMessage
                  v-for="(message, i) in activeMessages"
                  :message="message"
                  :key="i"
                />
              </div>
            </div>

            <!-- Input -->
            <div
              class="bg-gray-300 px-4 py-4 flex items-center"
              v-if="activeDID && uploadedFile && !didList[activeDID].is_deleted"
            >
              <p class="flex-1 pl-1">
                {{ uploadedFile ? uploadedFile.name : "or drag and drop" }}
              </p>
              <XIcon
                class="h-5 w-5 transform rotate-90 cursor-pointer rotate-180"
                aria-hidden="true"
                @click="resetFile"
              />
            </div>
            <div
              class="px-4 py-4 flex items-center"
              v-if="activeDID && !didList[activeDID].is_deleted"
            >
              <div>
                <input
                  ref="fileUpload"
                  type="file"
                  hidden
                  @change="fileChanged"
                />
                <PaperClipIcon
                  class="h-5 w-5 transform rotate-90 cursor-pointer rotate-180"
                  aria-hidden="true"
                  @click="chooseFiles"
                />
              </div>
              <div class="flex-1 mx-4">
                <input
                  v-model="textMessage"
                  class="w-full border rounded px-2 py-2"
                  type="text"
                  placeholder="Type a message"
                  @keydown.enter="send"
                />
              </div>
              <div>
                <PaperAirplaneIcon
                  class="h-5 w-5 transform rotate-90 cursor-pointer"
                  aria-hidden="true"
                  @click="send"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SMSLeftHeader from "@/widgets/sms/SMSLeftHeader";
import SMSContact from "@/widgets/sms/SMSContact";
import SMSRightHeader from "@/widgets/sms/SMSRightHeader";
import SMSMessage from "@/widgets/sms/SMSMessage";
import {
  ChevronDownIcon,
  PaperAirplaneIcon,
  XIcon,
} from "@heroicons/vue/solid";
import { PaperClipIcon } from "@heroicons/vue/outline";
import { mapActions, mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import _ from "lodash";

export default {
  components: {
    SMSMessage,
    SMSRightHeader,
    SMSContact,
    SMSLeftHeader,
    PaperAirplaneIcon,
    PaperClipIcon,
    XIcon,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    ChevronDownIcon,
  },
  created() {
    this.setupMode(this.$props.outreach);
  },
  props: {
    outreach: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setupMode(OUTREACH_MODE) {
      this.setOutreachMode(OUTREACH_MODE);
      this.listContacts({ phone_number: null, outreach: OUTREACH_MODE });
      this.listDIDs({ outreach: OUTREACH_MODE });
    },
    updateActiveDID(did) {
      this.activeDID = did;
      if (
        did &&
        this.activeContact.messages_dids &&
        (this.activeContact.messages_dids[did] ?? false)
      ) {
        this.activeContact.messages_dids[did].seen = true;
        this.updateContactSeen();
      }
    },
    updateContactSeen() {
      this.markAsSeen({
        didNumber: this.activeDID,
        number: this.activeContact.number,
      });
      for (let did in this.activeContact.messages_dids) {
        if (!this.activeContact.messages_dids[did].seen) {
          return (this.activeContact.seen = false);
        }
      }
      return (this.activeContact.seen = true);
    },
    searchContacts() {
      this.listContacts({
        phone_number: this.searchText,
        outreach: this.outreachMode,
      });
    },
    async setActiveContact(contact, fromSearch = false) {
      this.activeContact = contact;
      this.updateActiveDID(contact.last_message_did ?? null);
      if (this.searchText) {
        await this.listContacts({
          phone_number: null,
          outreach: this.outreachMode,
        });
        this.searchText = "";
      }
      if (fromSearch) {
        contact.number = contact.number.replace("Start New Chat With ", "");
        this.contacts.unshift(contact);
      }
      if (this.uploadedFile) {
        this.resetFile();
      }
    },
    scrollToBottom() {
      const container =
        this.$el.getElementsByClassName("message-box")[
          this.$el.getElementsByClassName("message-box").length - 1
        ];
      if (container) container.scrollIntoView();
    },
    chooseFiles() {
      this.$refs.fileUpload.click();
    },
    fileChanged(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadedFile = files[0];
      this.$nextTick(this.scrollToBottom);
    },
    resetFile() {
      this.uploadedFile = null;
      this.$refs.fileUpload.value = null;
    },
    async send(e) {
      e.preventDefault();

      const textMessage = this.textMessage;
      this.textMessage = "";
      await this.sendSMS({
        didNumber: this.activeDID,
        number: this.activeContact.number,
        textMessage: textMessage,
        file: this.uploadedFile,
      });
      this.resetFile();
    },
    ...mapActions("smsStore", [
      "listContacts",
      "sendSMS",
      "markAsSeen",
      "setOutreachMode",
    ]),
    ...mapActions("calls", ["listDIDs"]),
  },
  computed: {
    activeMessages() {
      const DIDMessages = this.activeContact.messages_dids[this.activeDID] ?? {
        messages: [],
        seen: true,
      };
      return DIDMessages.messages ?? [];
    },
    searchContact() {
      return {
        number: `Start New Chat With ${this.searchText}`,
        last_message_text: "",
        last_message_time: new Date().getTime() / 1000,
        messages_dids: {},
        seen: true,
      };
    },
    ...mapGetters("calls", ["DIDs"]),
    ...mapGetters("smsStore", ["contacts", "outreachMode"]),
    didList() {
      console.log(_.keyBy(this.DIDs, "did_number"));
      return _.keyBy(this.DIDs, "did_number");
    },
  },
  data() {
    return {
      activeDID: null,
      activeContact: null,
      loading: false,
      textMessage: "",
      searchText: "",
      uploadedFile: null,
    };
  },
  watch: {
    outreach: {
      handler: function (newValue) {
        this.setupMode(newValue);
      },
    },
    contacts: {
      handler: function (newValue) {
        if (this.activeContact) {
          let activeContact = newValue.filter(
            (contact) => contact.number === this.activeContact.number
          )[0];
          if (activeContact) this.activeContact = activeContact;
          if (this.activeDID) {
            this.markAsSeen({
              didNumber: this.activeDID,
              number: this.activeContact.number,
            });
          }
        }
      },
    },
    activeContact: {
      handler: function () {
        this.$nextTick(this.scrollToBottom);
      },
    },
    activeDID: {
      handler: function () {
        this.$nextTick(this.scrollToBottom);
      },
    },
  },
};
</script>
