<template>
  <div class="py-2 px-3 bg-grey-lighter flex flex-row items-center">
    <div>
      <img
        id="profile-menu"
        class="h-8 w-8 rounded-full"
        :src="`https://ui-avatars.com/api/?name=${userName}&background=1F2937&color=FFF`"
        alt=""
      />
    </div>
    <div class="ml-4">
      <p class="text-grey-darkest">{{ userName }}</p>
    </div>
  </div>
</template>

<script>
import StorageUtil from "@/utils/LocalStorageUtil";

export default {
  computed: {
    userName() {
      return StorageUtil.getUserData()?.name;
    },
  },
};
</script>
