<template>
  <div
    :class="[
      contact.number === activeNumber
        ? 'bg-grey-light'
        : 'bg-white hover:bg-grey-lighter',
      'px-3 flex items-center cursor-pointer',
    ]"
  >
    <div
      :class="[
        contact.seen ? '' : 'text-red-700',
        'pl-4 flex-1 border-b border-grey-lighter py-4 w-full',
      ]"
    >
      <div class="flex items-bottom justify-between">
        <p class="text-grey-darkest">{{ contact.number }}</p>
        <p class="text-xs text-grey-darkest">{{ receivedAt }}</p>
      </div>
      <p class="text-grey-dark mt-1 text-sm truncate">
        {{ contact.last_message_text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      default: null,
    },
    activeNumber: {
      type: String,
      default: null,
    },
  },
  computed: {
    receivedAt() {
      const date = new Date(this.contact.last_message_time * 1000);
      return date.toLocaleString("en-US", { timeZone: "EST" });
    },
  },
};
</script>
