<template>
  <div
    v-if="!message.deleted_at || !screenshotMode"
    :class="[message.receive ? '' : 'justify-end', 'flex mb-2 message-box']"
  >
    <div
      :class="[message.deleted_at ? 'bg-red-400' : '', 'rounded-2xl py-2 px-3']"
      :style="[
        message.deleted_at
          ? ''
          : message.receive
          ? 'background-color: #e9e9eb; color: black'
          : 'background-color: #34c759; color: #ffffff',
      ]"
    >
      <p class="w-3 ml-auto" v-if="!screenshotMode">
        <InboxInIcon
          v-if="message.deleted_at"
          class="h-3 w-3"
          aria-hidden="true"
          @click="restoreMessage({ ...message, outreach: this.outreachMode })"
        />
        <XIcon
          v-else
          class="h-3 w-3"
          aria-hidden="true"
          @click="deleteMessage({ ...message, outreach: this.outreachMode })"
        />
      </p>
      <div
        class="media max-w-md mx-auto"
        :key="index"
        v-for="(mediaContent, index) in message.media"
      >
        <div
          class="my-1"
          v-if="
            mediaContent.content_type == null ||
            mediaContent.content_type.startsWith('image/')
          "
        >
          <a :href="mediaContent.url" target="_blank" title="Open in new tab">
            <img class="mx-auto" :src="mediaContent.url" alt="MMS Media" />
          </a>
        </div>
        <div
          class="video"
          v-else-if="mediaContent.content_type.startsWith('video/')"
        >
          <video controls class="mx-auto">
            <source :src="mediaContent.url" :type="mediaContent.content_type" />
          </video>
        </div>
      </div>
      <p
        class="max-w-md text-sm"
        v-if="message.message"
        style="word-break: break-word"
      >
        {{ message.message }}
      </p>
      <p class="text-right text-xs text-grey-dark mt-1" v-if="!screenshotMode">
        <span v-if="message.username">Sent by: ({{ message.username }})</span>
        {{ receivedAt }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { InboxInIcon, XIcon } from "@heroicons/vue/solid";

export default {
  components: {
    XIcon,
    InboxInIcon,
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions("smsStore", ["deleteMessage", "restoreMessage"]),
  },
  computed: {
    receivedAt() {
      const date = new Date(this.message.received_at * 1000);
      return date.toLocaleString("en-US", { timeZone: "EST" });
    },
    ...mapGetters("smsStore", ["screenshotMode", "outreachMode"]),
  },
};
</script>
